.coursesFor{
    width: 90%;
    display: flex;
    margin: 0 auto 5%;
    flex-direction: column;
    /* gap: 20%; */
    justify-content: space-between;
    /* align-items: center; */
}
.coursesFor>aside:first-child{
    flex-basis: 40%;
    height: max-content !important;
    margin-bottom: 20px;
    border: 2px solid #eee;
    border-radius: 5px;
    padding: 3%;
    text-align: justify;
    word-break: break-all;
    box-shadow: 1px 1px 8px 1px rgb(196, 196, 196);
}
.coursesFor>aside:last-child{
    flex-basis: 45%;
    border: 2px solid #eee;
    border-radius: 5px;
    padding: 3%;
    text-align: justify;
    box-shadow: 1px 1px 8px 1px rgb(196, 196, 196);
}
.coursesFor ul{
    list-style: none;
    padding-left: 0;
    /* list-style: url(""); */
}
.coursesFor ul li{
    padding: 10px 10px 10px 0px;
    word-break: keep-all;
}
.coursesFor ul li i{
    padding-right: 10px;
    color:#f8931f;
    font-weight: 900;
}
.coursesFor h3{
    text-align: center;
    color:#f8931f;
    font-weight: 900;
}