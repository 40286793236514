@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.coursesForr {
  width: 100%;
  display: flex;
  gap: 25px;
  padding: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  box-shadow: 1px 1px 8px 1px rgb(196, 196, 196);
  font-family: "Poppins", sans-serif !important;
}
.coursesForr {
  flex-basis: 40%;
  height: max-content !important;
  margin-bottom: 20px;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 3%;
  box-shadow: 1px 1px 8px 1px rgb(196, 196, 196);
}
.coursesForr > aside:last-child {
  flex-basis: 45%;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 3%;
  text-align: justify;
  box-shadow: 1px 1px 8px 1px rgb(196, 196, 196);
}
.coursesForr ul {
  list-style: none;
  padding-left: 0;
  list-style: url("");
}
.coursesForr ul li {
  padding: 10px 10px 10px 0px;
  word-break: keep-all;
}
.coursesForr ul li i {
  padding-right: 10px;
  color: #f8931f;
  font-weight: 900;
}
.coursesForr h3 {
  text-align: center;
  color: #f8931f;
  font-weight: 900;
}

@media (max-width: 600px) {
  .subContainer {
    width: "130%" !important;
    display: flex !important;
    flex-direction: column !important;
    
  }
  .tabs{
    width: 100% !important;
  }
  .tabContainer {
    margin-top: 21% !important;
    width: 100% !important;
    
  }
  .subTab {
    width: 100% !important;
    
   
  }
  .cardsTab {
    width: 100% !important;    
  }
  .coursesForr {
    width: 100% !important;
    box-shadow: none;
   
  }
  .boxTab {
    margin-left: -3%;
  
  }
  .searchField {
    width: 80%;
    text-align: center !important;
    align-items: center !important;
    margin-left: 10%;
  }
  .paginationBlock{
    width:"100%";
    margin-left:30% !important;
    
  }
}
