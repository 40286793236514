/* Common  */
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #f8931f;
  text-decoration: none;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #f8931f;
  text-decoration: none;
}

#header a:active {
  color: #f8931f;
}

a:hover {
  color: #f8931fb8;
  text-decoration: none;
}

#activeLink {
  color: #f8931fb8;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/* header */

#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  position: fixed !important;
  top: 0 !important;
  border-bottom: 2px solid rgb(211, 208, 208);
  position: relative;
  /* box-shadow: 1px 1px 8px 1px #b3b1b1; */
}

#header.header-scrolled {
  padding: 12px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #111111;
}

#header .logo a span {
  color: #f8931f;
}

#header .logo img {
  max-height: 40px;
}

.hamMenuBlock {
  position: absolute;
  right: 20px;
  top: 80px;
  border-radius: 5px;
  width: 150px;
  padding-right: 10px;
  display: none;
  padding-left: 10px !important;
  height: 225px;
  text-align: left;
  background-color: #fff;
}

.hamMenuBlock ul {
  list-style: none;
  padding-left: 0px !important;
}

.hamMenuBlock ul li {
  border-bottom: 1px solid gray;
}

.hamMenuBlock ul a {
  padding: 10px 5px;
  text-align: left !important;
}

.hamMenuBlock ul :last-child {
  border: none;
}

/*--------------------------------------------------------------
# Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  margin-left: 30px;
  background: #f8931f;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}

.get-started-btn:hover {
  background: #111111;
  color: #fff;
}

.get-started-btn1 {
  margin-left: 30px;
  background: #f8931f;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  margin-left: 80%;
}

.get-started-btnbatch {
  background: #f8931f;
  color: #fff;
  border-radius: 4px;
  padding: 5px 20px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  margin-left: -108%;
}

.get-started-btnbatch:hover {
  background: #111111;
  border: 1px solid #f8931f;
  color: #fff;
}

.get-started-btn1:hover {
  /* background: #111111; */
  color: #fff;
}

@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #111111;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #f8931f;
  border-bottom: 1px solid #f7890b;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #f8931f;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #111111;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #111111;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #f8931f;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #f8931f;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/* Welcome Section */

#hero {
  width: 100%;
  height: 95vh;
  background: url("./assets/img/hero-bg11.webp") top center no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  padding-top: 82px;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h1 {
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  color: rgba(238, 238, 238, 0.932);
}

#hero h2 {
  color: #f8931f;
  font-weight: bold;
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #f8931f;
  border: 2px solid #f8931f;
}

#hero .btn-get-started:hover {
  background: transparent;
  border-color: #fff;
}

@media (max-width: 768px) {
  #hero {
    text-align: center;
    padding-top: 58px;
  }

  #hero h1 {
    font-size: 28px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }

  #hero h5 {
    font-size: x-small !important;
    line-height: 20px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 90vh;
  }

  #hero h5 {
    font-size: x-small !important;
    line-height: 20px;
  }
}

/* generel Section */

section {
  padding: 60px 0;
  overflow: hidden;
  position: relative;
  margin-top: 3%;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  /* margin-bottom: 20px; */
  margin: 26px 0;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #f8931f;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

.section-bg {
  padding: 120px 0;
  color: #fff;
}

.section-bg:before {
  content: "";
  background: #1b1b1b;
  /* change 1 */
  /* background: #e03a3db1; */
  position: absolute;
  bottom: 60px;
  top: 60px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}

/* Facts Box */
.counts {
  padding-top: 80px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  z-index: 100;
}

.counts .count-box i {
  position: absolute;
  width: 54px;
  height: 54px;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #fff;
  color: #f8931f;
  border-radius: 50px;
  border: 2px solid #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 700;
  color: #111111;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/* About */
#about h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #f8931f;
  bottom: 0;
  left: calc(50% - 25px);
}

#about h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.about .container {
  position: relative;
  z-index: 10;
}

.about .content {
  padding: 30px 30px 30px 0;
}

.about .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.about .content p {
  margin-bottom: 30px;
  /* text-align: justify !important; */
}

.about .content .about-btn {
  padding: 8px 30px 9px 30px;
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  border: 2px solid #f8931f;
}

.about .content .about-btn i {
  font-size: 16px;
  padding-left: 5px;
}

.about .content .about-btn:hover {
  background: #f8931fbb;
  background: #f8931f;
}

.about .icon-boxes .icon-box {
  margin-top: 30px;
}

.about .icon-boxes .icon-box i {
  font-size: 40px;
  color: #f8931f;
  margin-bottom: 10px;
}

.about .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.about .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}

@media (max-width: 1200px) {
  .about .content {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .about {
    text-align: center;
  }
}

/* Courses */
#tabs h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #f8931f;
  bottom: 0;
  left: calc(50% - 25px);
}

#tabs h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  /* margin-bottom: 20px; */
  margin: 26px 0px;
  padding-bottom: 20px;
  position: relative;
}

.tabs .nav-tabs {
  border: 0;
}

.tabs .nav-link {
  border: 1px solid #b9b9b9;
  padding: 15px;
  transition: 0.3s;
  color: #111111;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabs .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.tabs .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.tabs .nav-link:hover {
  color: #f8931f;
}

.tabs .nav-link.active {
  background: #f8931f;
  color: #fff;
  border-color: #f8931f;
}

@media (max-width: 768px) {
  .tabs .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .tabs .nav-link {
    padding: 15px;
  }

  .tabs .nav-link i {
    font-size: 24px;
  }
}

.tabs .tab-content {
  margin-top: 30px;
}

.tabs .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.tabs .tab-pane ul {
  list-style: none;
  padding: 0;
}

.tabs .tab-pane ul li {
  padding-bottom: 10px;
}

.tabs .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #f8931f;
}

.tabs .tab-pane p:last-child {
  margin-bottom: 0;
}

.img-fluid {
  height: 70% !important;
}

/* Batch Schedule */

.batchSchedule {
  list-style: none;
  /* border: 1px solid #f8931f8b; */
  /* border-radius: 15px 50px; */
  padding: 10px;
  background-color: #444444;
}

.batchSchedule li {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.batchSchedule li:nth-child(even) {
  background-color: #666;
}

.services .icon-box {
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 6px;
  background: #252525;
  transition: 0.3s;
}

.services .icon-box:hover {
  background: #2b2b2b;
}

.services .icon-box i {
  float: left;
  color: #f8931f;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 span {
  color: #f8931f;
  /* color: #fff; */
  font-weight: bolder;
  transition: 0.3s;
}

/* 
.services .icon-box h4 span:hover {
  text-decoration: underline;
} */

.services .icon-box .icon-box:hover h4 a {
  color: #f8931f;
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}

/* Enquiry */

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px 0 50px 0;
  border-radius: 4px;
  
}

.contact .info-box i {
  font-size: 32px;
  color: #f8931f;
  border-radius: 50%;
  padding: 8px;
  /* border: 2px dotted #f8d4d5; */
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .EnqForm {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  border-radius: 4px;
}

.contact .EnqForm .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .EnqForm .error-message br+br {
  margin-top: 25px;
}

.contact .EnqForm .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .EnqForm .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .EnqForm .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .EnqForm .form-group {
  margin-bottom: 25px;
}

.contact .EnqForm input,
.contact .EnqForm textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .EnqForm input:focus,
.contact .EnqForm textarea:focus {
  border-color: #111111;
}

.contact .EnqForm input {
  padding: 10px 15px;
}

.contact .EnqForm textarea {
  padding: 12px 15px;
}

.contact .EnqForm button[type="submit"] {
  background: #f8931f;
  border: 0;
  padding: 10px 32px;
  color: #fff;
  transition: 0.4s;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #f8931f;
  transition: 0.5s all ease-in-out;
}

.contact .EnqForm button[type="submit"]:hover {
  background-color: #fff;
  color: #f7890b;
  font-weight: bold;
  transition: 0.5s all ease-in-out;
  border: 1px solid #f8931f;
}

/* .contact .contactTittle{
    color: #f86b1f;
    text-align: center;
    margin-bottom: 5%;
    font-weight: bold;
} */
/* Footer */

#footer {
  color: #fff;
  font-size: 14px;
  background: #111111;
  cursor: pointer;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #f8931f;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  /* color: #fff; */
  color: #111;
  font-weight: bolder;
  line-height: 0;
}

.back-to-top:hover {
  background: #f8931fc7;
  color: #fff;
}

#back {
  visibility: visible;
  opacity: 1;
}

.contactTittle span {
  display: block;
}

@media (max-width: 900px) {
  #hero {
    width: 100%;
    height: 90vh;
    background: url("./assets/img/hero-bgmob.webp") top center no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 82px;
  }

  .contactTittle {
    font-size: xx-small;
  }

  .contactTittle span {
    display: inline;
  }

  .section-title>h2 {
    font-size: x-large;
  }

  .info-box {}
}


@media (max-width: 768px) {
  .services .icon-box {
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 6px;
    background: #252525;
    transition: 0.3s;
    width: 600px !important;
    background: transparent;
  }

  @media (max-width: 768px) {
    .batchSchedule {
      width: fit-content;
    }

    .rowX {
      width: 100vw;
    }

    .col-md-6 {
      padding: 2px;
      /* background: #18d26e; */
      overflow-x: scroll;
    }

    .row>.col-md-6 {
      overflow-x: unset !important;
    }

    .dataAndTime {
      width: 50vw !important;
    }

    #services .icon-box {
      margin-bottom: 20px;
      border-radius: 6px;
      width: 600px !important;
      background: transparent;
      transition: 0.3s;
    }
  }
}