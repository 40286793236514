#CardMainBlock {
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: -5vh;
}

#CardMainBlock>article {
  display: flex;
  width: 87%;
  margin: 0 auto;
  justify-content: space-evenly;
}

.containerHeading {
  margin-bottom: 3%;
}

.headingBlock {
  color: #fd911d;
  margin-top: 2%;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 33px;
}

.card {
  width: 26%;
  box-shadow: 1px 2px 5px gray;
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  background-color: rgb(245, 242, 239);

}

.card:hover {
  transform: scale(1.03) rotateY(360deg);
  transition: 1s all ease-in-out;
  cursor: pointer;
}

.card>h1 {
  text-align: center;
  border-bottom: 2px solid #fd911d;
  margin-bottom: 20px;
  color:#e87a05;
  font-weight: 900 !important;
  font-size: 25px !important;
}
.card>h1 b{
  color: rgb(44, 42, 42);
}

.card>ul {
  /* list-style: none; */
  padding-inline-start: 20px;
}

.card>ul li {
  padding: 5px 10px;
  width: 100%;
  font-weight: 700;
  text-align: left !important;
  color: #111;
}

.cardSecond {
  margin: 0 auto;
  width: 77%;
  background-color: #fd911df0;
  color: white;
  box-shadow: 1px 2px 5px gray;
  padding: 20px;
  margin-top: 5% !important;
  border-radius: 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.cardSecond:hover {
  transform: scale(1.03);
}

.subHead {
  display: inline-block;
  color:white;
  font-weight: 900;
  text-align: left;
  border-bottom: 1px solid #fff ;
  margin-bottom: 20px;
  font-size: 30px !important;
}
.subHead1{
  font-size: 20px;
}

.cardSecond ul {
  list-style: disc !important;
  display: flex;
  justify-content: space-evenly;
}
.cardSecond ul::marker{
  color: white !important;
}

.cardSecond ul li {
  padding: 5px 10px;
  font-weight: 700;
}

@media (max-width: 600px) {
  #CardMainBlock>article {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }

  .headingBlock {
    font-size: 32px;
  }

  .card {
    width: 80%;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 10px;
  }

  .cardSecond {
    width: 65%;
  }

  .cardSecond ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: flex-start;
    text-align: left !important;
  }

  .cardSecond ul li {
    text-align: left !important;
  }

  .subHead {
    text-align: center;
    font-size: 24px !important;
    width: 100%;
    color: #fff;
    font-weight: bolder !important;
    border-bottom: 1px solid #fd911d;
    margin-bottom: 10px;
  }

  .subHead1 {
    font-size: 17px;
  }
}
