@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.coursesForr {
  width: 90%;
  display: flex;
  gap: 10px;
padding:10px;
  flex-wrap: wrap;
  flex-direction: row;
  box-shadow: 1px 1px 8px 1px rgb(196, 196, 196);
  font-family: 'Poppins', sans-serif !important;
}
.coursesForr  {
  flex-basis: 40%;
  height: max-content !important; 
   margin-bottom: 20px;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 3%;
  box-shadow: 1px 1px 8px 1px rgb(196, 196, 196); 
}  
 .coursesForr > aside:last-child {
  flex-basis: 45%;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 3%;
  text-align: justify;
  box-shadow: 1px 1px 8px 1px rgb(196, 196, 196);
} 
.coursesForr ul {
  list-style: none;
  padding-left: 0; 
   list-style: url(""); 
 } 
 .coursesForr ul li {
  padding: 10px 10px 10px 0px;
  word-break: keep-all;
}
.coursesForr ul li i {
  padding-right: 10px;
  color: #f8931f;
  font-weight: 900;
} 
.coursesForr h3 {
  text-align: center;
  color: #f8931f;
  font-weight: 900;
}

